/*
 * @FilePath: /daren-html-source/src/router/index.js
 * @Description: 路由
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import utils from '@/utils'
import conf from '@/utils/config/index'
import { LoginSSOAPI } from '@/utils/apiList/user'
import store from '@/store'
import HomeView from '../views/HomeView.vue'
import Account from '@/views/Account/index.vue'
import IncomeReflect from '@/views/IncomeReflect/index.vue'
import FmCloud from '@/views/FmCloud/index.vue'
import Homepage from '@/views/Homepage/index.vue'
import Subscribe from '@/views/Subscribe/index.vue'
import MemberRebate from '@/views/MemberRebate/index.vue'
import OfflineRebate from '@/views/OfflineRebate/index.vue'
import Invite from '@/views/Invite/index.vue'
import Help from '@/views/Help/index.vue'
import { userInfoAPI } from '@/utils/apiList/user.js'
Vue.use(VueRouter)

function getUser() {
  return new Promise(resolve => {
    userInfoAPI()
      .then(res => {
        if (res.status == 1) {
          store.commit('setAttr', {
            name: 'userInfo',
            val: res.data,
          })
          if (res.data.is_rate_flow == 0) {
            sessionStorage.setItem('isFirst', 1)
          }
          localStorage.setItem('isDaren', res.data.is_rate_flow)
          resolve(true)
        } else if (res.status == 0) {
          resolve(true)
        } else {
          resolve(true)
        }
      })
      .catch(err => {})
  })
}

const routes = [
  {
    path: '/:lang(en-US|zh-HK)?/homepage',
    name: 'homepage',
    component: Homepage,
    meta: {
      requireLogin: false,
      requireDaren: false,
      title: '飞猫达人中心',
    },
  },
  {
    path: '/:lang(en-US|zh-HK)?/',
    name: 'HomeView',
    component: HomeView,
    redirect: '/drive',
    meta: {
      requireLogin: true,
      requireDaren: true,
      title: '飞猫达人中心',
    },
    children: [
      {
        path: '/:lang(en-US|zh-HK)?/drive',
        name: 'drive',
        component: FmCloud,
        meta: {
          requireLogin: true,
          requireDaren: true,
          title: '飞猫达人中心',
        },
      },
      {
        path: '/:lang(en-US|zh-HK)?/account',
        name: 'account',
        component: Account,
        meta: {
          requireLogin: false,
          requireDaren: false,
          title: '飞猫达人中心',
        },
      },
      {
        path: '/:lang(en-US|zh-HK)?/income',
        name: 'income',
        component: IncomeReflect,
        meta: {
          requireLogin: true,
          requireDaren: true,
          title: '飞猫达人中心',
        },
      },
      {
        path: '/:lang(en-US|zh-HK)?/subscribe',
        name: 'subscribe',
        component: Subscribe,
        meta: {
          requireLogin: true,
          requireDaren: true,
          title: '飞猫达人中心',
        },
      },
      {
        path: '/:lang(en-US|zh-HK)?/memberRebate',
        name: 'memberRebate',
        component: MemberRebate,
        meta: {
          requireLogin: true,
          requireDaren: true,
          title: '飞猫达人中心',
        },
      },
      {
        path: '/:lang(en-US|zh-HK)?/offlineRebate',
        name: 'offlineRebate',
        component: OfflineRebate,
        meta: {
          requireLogin: true,
          requireDaren: true,
          title: '飞猫达人中心',
        },
      },
      {
        path: '/:lang(en-US|zh-HK)?/help',
        name: 'help',
        component: Help,
        meta: {
          requireLogin: true,
          requireDaren: true,
          title: '飞猫达人中心',
        },
      },
    ],
  },
  {
    path: '/:lang(en-US|zh-HK)?/invite',
    name: 'invite',
    component: Invite,
    meta: {
      title: '飞猫达人中心',
    },
  },
  {
    path: '*', // 此处需特别注意至于最底部
    redirect: '/drive',
  },
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
// 在全局导航守卫中检查元字段-全局前置守卫
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (utils.getCookie(conf.fmCookieName) && to.query.platfro) {
    localStorage.setItem('platfro', to.query.platfro)
    success = await getUser()
  }
  if (!utils.getCookie(conf.fmCookieName) && to.query.platfro) {
    localStorage.setItem('platfro', to.query.platfro)
    LoginSSOAPI({ token: to.query.platfro })
      .then(async res => {
        if (res.status == 1) {
          if (utils.getCookie(conf.fmCookieName)) {
            sessionStorage.setItem('isFirst', 1)
            store.commit('getNewCookieVuex')
            success = await getUser()
            if (localStorage.getItem('isDaren')) {
              next('/drive')
            } else {
              next('/homepage')
            }
          } else {
            setTimeout(() => {
              next(to.path)
            }, 600)
          }
        }
      })
      .catch(err => {})
    return
  } else if (to.query.platfro) {
    next(to.path)
  }
  // 遍历 $route.matched 来检查路由记录中的 meta 字段
  if (to.matched.some(record => record.meta.requireLogin)) {
    if (!utils.getCookie(conf.fmCookieName)) {
      if (to.name === 'homepage') {
        next()
      } else {
        router.push('/homepage')
      }
    } else {
      var success = ''
      if (utils.getCookie(conf.fmCookieName)) {
        if (
          localStorage.getItem('isDaren') == null ||
          localStorage.getItem('isDaren') == undefined
        ) {
          success = await getUser()
        }
      }

      if (localStorage.getItem('isDaren') == 0) {
        let arr = ['account', 'homepage']
        if (arr.includes(to.name)) {
          next()
        } else {          
          router.push('/account')
        }
      } else {
        // let arr1 = ['homepage']
        // if (arr1.includes(to.name)) {
        //   router.push('/drive')
        // } else {
          next()
        // }
      }
    }
  } else {
    next()
  }
  if (localStorage.getItem('lang') == 'en') {
    if (to.fullPath.indexOf('/en-US') == -1) {
      next({ path: '/en-US' + to.fullPath })
    } else {
      next()
    }
  }else if(localStorage.getItem('lang') == 'zhhk'){
    if (to.fullPath.indexOf('/zh-HK') == -1) {
      next({ path: '/zh-HK' + to.fullPath })
    } else {
      next()
    }
  } else {
    if (to.fullPath.indexOf('/en-US') == -1 || to.fullPath.indexOf('/zh-HK') == -1) {
      next()
    } else {
      const newPath = to.fullPath.replace('/en-US', '').replace('/zh-HK', '');
      next({ path: newPath })
    }
  }
})
// 全局后置守卫
router.afterEach(() => {
  store.commit('toTop')
  // NProgress.done();
})

export default router
