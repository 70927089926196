<!--
 * @FilePath: /daren-html-source/src/views/HomeView.vue
 * @Description: 
-->
<template>
  <div
    class="app-container"
    :style="`backgroundImage:url(${$utils.getPng('mainBg1')})`"
  >
    <div class="app-box">
      <NavTop></NavTop>
      <div class="app-bottom">
        <NavLeft></NavLeft>
        <div class="app-bottom-right" ref="scrollBox">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavTop from "@/components/NavTop/index.vue";
import NavLeft from "@/components/NavLeft/index.vue";
export default {
  components: { NavTop, NavLeft },
  mounted() {
    this.$store.commit("setAttr", {
      name: "toTop",
      val: this.toTop,
    });
  },
  methods: {
    toTop() {
      if(this.$refs.scrollBox) this.$refs.scrollBox.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
// @font-face {
//   font-family: "AlibabaPuHuiTiB";
//   src: url("https://webimgtest.fmapp.com/Public/web/font/PuHuiTi/Alibaba-PuHuiTi-Bold.ttf");
// }
// @font-face {
//   font-family: "Rubik";
//   src: url("https://webimgtest.fmapp.com/Public/web/font/PuHuiTi/Rubik-Medium.ttf");
// }
.app-container {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: no-repeat center;
  background-size: cover;
  .app-box {
    width: 100%;
    height: 100vh;
    min-width: 980px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .app-bottom {
      width: 1200px;
      height: calc(100vh - 70px);
      display: flex;
      margin: 0 auto;
      margin-top: 20px;
      background: #f7f7f7;
      box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px 8px 0px 0px;

      .app-bottom-right {
        width: calc(1200px - 172px);
        height: 100%;
        padding: 20px 25px 0 25px;
        margin: 2px 2px 0 0;
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }
}
</style>
