<!--
 * @FilePath: /daren-html-source/src/components/MemRbCom/index.vue
 * @Description: 会员返利收益组件
-->
<template>
  <div class="memRbCom">
    <div class="pub-tit0" v-if="title" style="margin-top: 40px">
      {{ title }}
    </div>
    <div class="memberIncome">
      <div
        class="item"
        :style="`backgroundImage:url(${$utils.getPng('discodebg2')})`"
      >
        <div class="iTop">
          <svg-btn icon-class="icon4" />
          <div class="ianme">{{ $t('MemRbCom.Today') }}</div>
        </div>
        <div class="idown">
          <div v-if="lang =='en'" class="money">￥</div>
          <div class="num">{{ memberList.today_coupon }}</div>
          <div v-if="lang =='zh' || lang == 'zhhk'" class="money">元</div>
        </div>
      </div>
      <div
        class="item"
        :style="`backgroundImage:url(${$utils.getPng('discodebg2')})`"
        style="margin: 0 24px"
      >
        <div class="iTop">
          <svg-btn icon-class="icon4" />
          <div class="ianme">{{ $t('MemRbCom.Yesterday') }}</div>
        </div>
        <div class="idown">
          <div v-if="lang =='en'" class="money">￥</div>
          <div class="num">{{ memberList.yesterday_coupon }}</div>
          <div v-if="lang =='zh' || lang == 'zhhk'" class="money">元</div>
        </div>
      </div>
      <div
        class="item item3"
        :style="`backgroundImage:url(${$utils.getPng('discodebg3')})`"
      >
        <div class="iTop">
          <svg-btn icon-class="icon4" />
          <div class="ianme">{{ $t('MemRbCom.CurrentEarnings') }}</div>
        </div>
        <div class="idown">
          <div v-if="lang =='en'" class="money">￥</div>
          <div class="num">{{ memberList.month_coupon }}</div>
          <div v-if="lang =='zh' || lang == 'zhhk'" class="money">元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    memberList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      coupon: {
        today_coupon: 0,
        yesterday_coupon: 0,
        month_coupon: 0,
        lang: ''
      },
    };
  },
  mounted() {
    this.lang = localStorage.getItem('lang')??'zh'
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.pub-tit0 {
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 24px;
  margin-top: 40px;
}
.memberIncome {
  display: flex;
  margin-top: 20px;
  .item {
    width: 244px;
    height: 136px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 26px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .iTop {
      display: flex;
      height: 24px;
      font-weight: 500;
      font-size: 16px;
      color: #2c2c2c;
      line-height: 24px;
      align-items: center;
      .ianme {
        margin-left: 4px;
      }
    }
    .idown {
      display: flex;
      align-items: flex-end;

      .num {
        height: 44px;
        font-weight: 600;
        font-size: 36px;
        color: #f67942;
        line-height: 44px;
      }
      .money {
        height: 24px;
        font-size: 16px;
        color: #2c2c2c;
        line-height: 24px;
        margin: 0 8px 4px 8px;
      }
    }
  }
  .item3 {
    width: 396px;
    .iTop {
      color: #ffffff;
    }
    .idown {
      .num,
      .money {
        color: #ffffff;
      }
    }
  }
}
</style>
