<template>
  <div class="homepage">
    <div class="header">
      <div style="display: flex;height: 28px;margin-left: 29px">
        <svg-btn icon-class="logo" style="height: 28px;width: 28px;margin-right: 11px" />
        <svg-btn icon-class="logo-text" style="height: 28px;width: 185px;" />
      </div>
      <div style="display: flex;align-items: center;">
        <div class="langBox">
          <div class="lang">
            <span class="langTest">{{
              $i18n.locale == 'zhhk' ? '繁體中文' : $i18n.locale == 'en'? 'English':'简体中文'
            }}</span>
            <svg-btn class="iconBottom" icon-class="icon-bottom"></svg-btn>
          </div>
          <div class="langMoreBox"></div>
          <div class="langMore">
            <div class="one" @click="checkLang('en')">
              <div class="ontit">English</div>
            </div>
            <div class="one one2" @click="checkLang('zh')">
              <div class="ontit">简体中文</div>
            </div>
            <div class="one one2" @click="checkLang('zhhk')">
              <div class="ontit">繁體中文</div>
            </div>
          </div>
        </div>
        <div v-if="fmCookieName">
          <div class="userGen">
            <img :src="userInfo.logo" alt="" />
            <span>{{ userInfo.nickname }}</span>
            <svg-btn class="iconBottom" icon-class="icon-bottom" style="margin-left: 10px;"></svg-btn>
          </div>
          <div class="user2"></div>
          <div class="loginOut">
            <div class="one pointer" @click="loginoutClick(1)">
              <img class="imggr" :src="$utils.getPng('icon_22')" alt="" />
              <div class="ontit">{{ $t('nav.Account') }}</div>
            </div>
            <div class="one one2 pointer" @click="loginoutClick(2)">
              <img class="imggr" :src="$utils.getPng('icon_21')" alt="" />
              <div class="ontit">{{ $t('nav.SignOut') }}</div>
            </div>
          </div>
        </div>
        <div v-else style="display: flex;">
          <fm-button
          type="primary"
          round
          size="medium"
          class="button"
          style="margin-right: 16px;"
          @click="toLogin()"
          >{{ $t('home.Login') }}</fm-button>
          <fm-button
            type="info"
            round
            size="medium"
            @click="toLogin('register')"
            >{{ $t('home.Sign') }}</fm-button>
          </div>
      </div>
    </div>
    <div class="container" style="padding-top: 112px;">
      <h1 class="slogan">{{ $t('home.Slogan') }}</h1>
      <!-- <img :src="$utils.getPng(`slogan_${$i18n.locale}`)" height="50" alt=""> -->
      <div v-if="!fmCookieName" class="signup button" style="margin: 50px auto 88px;" @click="toLogin('register')">
        <span >{{ $t('home.ImmedSign') }}</span>
        <svg-btn icon-class="icon-arrow-right" style="margin-left: 4px;width: 14px;height: 14px;"/>
      </div>
      <div v-else class="signup button" style="margin: 50px auto 88px;" @click="enter">
        <span >{{ $t('home.Enter') }}</span>
        <svg-btn icon-class="icon-arrow-right" style="margin-left: 4px;width: 14px;height: 14px;"/>
      </div>
      <div style="display: flex;justify-content: space-between; position: relative;width: 959px;margin: 0 auto;">
        <div class="card" style="padding-right: 0">
          <img :src="$utils.getPng('home-icon-1')" width="52" alt="">
          <p>{{ $t('home.Signup') }}</p>
          <span>{{ $t('home.Simple') }}</span>
        </div>
        <div class="card">
          <img :src="$utils.getPng('home-icon-2')" width="52" alt="">
          <p>{{ $t('home.Master') }}</p>
          <span>{{ $t('home.Exclusive') }}</span>
        </div>
        <div class="card">
          <img :src="$utils.getPng('home-icon-3')" width="52" alt="">
          <p>{{ $t('home.ShareFile') }}</p>
          <span>{{ $t('home.Others') }}</span>
        </div>
        <svg-btn icon-class="guide-1" style="height: 104px;width: 80px;position: absolute;bottom: 0;left: 272px;" />
        <svg-btn icon-class="guide-2" style="height: 104px;width: 80px;position: absolute;bottom: 0;right: 272px;" />
      </div>
    </div>
    <div class="container gray" style="padding: 88px 0 200px;">
      <h1 style="margin-bottom: 14px;">{{ $t('home.Advantages') }}</h1>
      <p class="subtitle">{{ $t('home.Multiple') }}</p>
      <div class="pane">
        <div class="text" style="margin-right: 98px;">
          <h2>{{ $t('home.CardTitle1') }}</h2>
          <p>{{$t('home.CardText1')}}</p>
          <p>{{$t('home.CardText1_2prefix')}}<b>{{$t('home.CardText1_2Blod')}}</b>{{$t('home.CardText1_2suffix')}}</p>
          <p>{{$t('home.CardText1_3prefix')}}<b>{{$t('home.CardText1_3Blod')}}</b>{{$t('home.CardText1_3suffix')}}</p>
        </div>
        <img :src="$utils.getPng('home-card-1')" height="384" alt="">
      </div>
      <div class="pane">
        <img :src="$utils.getPng('home-card-2')" height="384" alt="">
        <div class="text" style="margin-left: 98px;">
          <h2>{{ $t('home.CardTitle2') }}</h2>
          <p>{{$t('home.CardText2')}}</p>
        </div>
      </div>
      <div class="pane">
        <div class="text" style="margin-right: 98px;">
          <h2>{{ $t('home.CardTitle3') }}</h2>
          <p>{{$t('home.CardText3')}}</p>
        </div>
        <img :src="$utils.getPng('home-card-3')" height="384" alt="">
      </div>
    </div>
    <div class="container" style="padding: 200px 0;">
      <h1 style="margin-bottom: 14px;">{{$t('home.Income')}}</h1>
      <p class="subtitle">{{$t('home.Random')}}</p>
      <div style="margin: 48px auto 0;width: 1220px;display: flex;flex-wrap: wrap;">
        <div v-for="(item, index) in income" class="item" :key="index">
          <img :src="item.src" width="36" height="36" alt="" style="margin-right: 12px;margin-top: 8px;">
          <div>
            <p>{{item.name}}</p>
            <p style="color: #8A8B92;font-weight: 400;margin-top: 2px;">提现{{item.value}}元（累计赚{{item.total}}元）</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container gray" style="padding-bottom: 82px;">
      <h1 style="margin-bottom: 14px;">{{$t('home.Feedback')}}</h1>
      <p class="subtitle">{{$t('home.Realization')}}</p>
      <div class="comment-box">
        <transition-group name="list" tag="ul" mode="out-in">
          <li class="comment" v-for="item in list" :key="item.id">
            <p>{{ item.comment }}</p>
            <div style="display: flex;align-items: center;">
              <img :src="item.src" width="36" height="36" alt="" style="margin-right: 12px;">
              <span>{{item.name}}</span>
            </div>
          </li>
        </transition-group>
      </div>
    </div>
    <div class="gray">
      <div style="background-color: #fff;display: flex;align-items: center;justify-content: center;height: 358px;border-radius: 100px 100px 0px 0px;">
        <h1 style="text-align: center;white-space: pre-line">{{$t('home.Enjoy')}}</h1>
        <!-- <div v-if="!fmCookieName" class="signup button" style="margin: 50px auto 88px;" @click="toLogin('register')">
          <span >{{ $t('home.ImmedSign') }}</span>
          <svg-btn icon-class="icon-arrow-right" style="margin-left: 4px;width: 14px;height: 14px;"/>
        </div>
        <div v-else class="signup button" style="margin: 50px auto 88px;" @click="enter">
          <span >{{ $t('home.Enter') }}</span>
          <svg-btn icon-class="icon-arrow-right" style="margin-left: 4px;width: 14px;height: 14px;"/>
        </div> -->
        <div v-if="!fmCookieName" class="signup button" style="margin-left: 40px;" @click="toLogin('register')">
          <span>{{ $t('home.ImmedSign') }}</span>
          <svg-btn icon-class="icon-arrow-right" style="margin-left: 4px;width: 14px;height: 14px;"/>
        </div>
        <div v-else class="signup button" style="margin-left: 40px;" @click="enter">
          <span >{{ $t('home.Enter') }}</span>
          <svg-btn icon-class="icon-arrow-right" style="margin-left: 4px;width: 14px;height: 14px;"/>
        </div>
      </div>
    </div>
    <Tips
      :isShow="showTips"
      @confirm="opDaren()"
      @tokefu="tokefu()"
      @handleClose="showTips=false"
    ></Tips>
    <ChangePhoneDiaVue
      ref="ChangePhoneDia"
      :userInfo="userInfo"
      newColor
      @confirm="openDaren"
      :showChangePhoneFlag="showChangePhoneFlag"
      @handleClose="showChangePhoneFlag = false"
    ></ChangePhoneDiaVue>
    <AddPayee :isShow="showAddpayFlag" @confirm="openDaren" newColor/>
  </div>
</template>

<script>
import { openDarenAPINew } from '@/utils/apiList/user'
import name from '../OfflineRebate/name';
import comment from './comment';
import ChangePhoneDiaVue from '@/components/ChangePhoneDIa/index.vue'
import Tips from '@/components/OpenDarenDia/step1.vue'
import AddPayee from '@/components/AddPayee/index.vue'
import RealName from '@/components/ChangePhoneDIa/realName.vue'

import {
  userInfoAPI,
  loginOutAPI
} from '@/utils/apiList/user.js'

export default {
  components: { Tips, RealName, ChangePhoneDiaVue, AddPayee },
  data() {
    return {
      activeName: '1',
      fmCookieName: this.$utils.getCookie(this.$glb.fmCookieName),
      income: [],
      list: [],
      userInfo: {},
      carousel: null,
      showChangePhoneFlag: false,
      showAddpayFlag: false,
      showTips: false,
    }
  },
  created() {
    const lang = { zh: 'zh-Hans', zhhk:'zh-Hant', en: 'en' }
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) location.replace(`${process.env.VUE_APP_H5_URL}/invite/#/pages/home/index?lang=${lang[this.$i18n.locale]}`)
    if(this.$utils.getCookie(this.$glb.fmCookieName)) this.getUser()
    for(let i=0;i<12;i++) {
      const value = parseInt(Math.random() * (89999 - 14899) + 14899)
      this.income.push({
        name: this.getName(),
        src: this.$utils.getPng(`avatar-${Math.ceil(Math.random()*9)}`),
        value: value/100,
        total: parseInt(Math.random() * (999999 - Math.ceil(value*1.5)) + Math.ceil(value*1.5))/100
      })
    }
    for(let item of comment) {
      this.list.push({
        id: Math.random(),
        comment: item,
        src: this.$utils.getPng(`avatar-${Math.ceil(Math.random()*9)}`),
        name: this.getName()
      })
    }
  },
  mounted() {
    const flag = this.$route.params.flag
    if (flag == 0) {
      this.showAddpayFlag = true
    } else if (flag == 2) {
      this.$refs.ChangePhoneDia.step = 2
      this.$refs.ChangePhoneDia.topTit = '绑定手机号'
      this.showChangePhoneFlag = true
    }
    this.start()
    document.addEventListener("visibilitychange", () => {
      if(document.visibilityState == "visible") {
        this.start()
      } else {
        clearInterval(this.carousel)
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.carousel)
  },
  methods: {
    bindConfirm() {
      if (this.userInfo.is_income_account == 0) {
        this.showAddpayFlag = true
      } else {
        this.userInfo.is_bind_phone = 1
        this.openDaren()
      }
    },
    getUser(tips=true) {
      return userInfoAPI()
        .then(res => {
          if (res.status == 1) {
            this.userInfo = res.data
            this.$store.commit('setAttr', {
              name: 'userInfo',
              val: res.data,
            })
            localStorage.setItem('isDaren', res.data.is_rate_flow)
            if (res.data.is_rate_flow != 1&&tips) {
              this.showTips = true
            }
          }
        })
        .catch(err => {})
    },
    toLogin(type) {
      const from = 'daren'
      const fromUrl = this.$glb.darenUrl + `/homepage`
      const encodedFrom = encodeURIComponent(from)
      const encodedFromUrl = encodeURIComponent(fromUrl)
      if (type == 'register') {
        window.location.href =
          this.$glb.fmpUrl +
          `/login?path=drive&tab=2&from=${encodedFrom}&fromUrl=${encodedFromUrl}`
      } else {
        window.location.href =
          this.$glb.fmpUrl +
          `/login?from=${encodedFrom}&fromUrl=${encodedFromUrl}`
      }
      return
    },
    checkLang(row) {
      this.$i18n.locale = row
      localStorage.setItem('lang', row)
      if (row == 'en') {
        if (this.$route.fullPath.indexOf('/en-US') == -1) {
          this.$router.push({ path: '/en-US' + this.$route.fullPath.replace('/zh-HK', '') })
        }
      }else if(row == 'zhhk'){
        if (this.$route.fullPath.indexOf('/zh-HK') == -1) {
          this.$router.push({ path: '/zh-HK' + this.$route.fullPath.replace('/en-US', '') })
        }
      } else {
        const newPath = this.$route.fullPath.replace('/en-US', '').replace('/zh-HK', '');
        this.$router.push({ path: newPath == '' ? '/' : newPath })
      }
    },
    openDaren() {
      this.getUser(false).then(() => {
        if (this.userInfo.is_bind_phone == 0) {
          this.$refs.ChangePhoneDia.step = 2
          this.$refs.ChangePhoneDia.topTit = '绑定手机号'
          this.showChangePhoneFlag = true
        } else if (this.userInfo.is_income_account == 0) {
          this.showChangePhoneFlag = false
          this.showAddpayFlag = true
        } else {
          openDarenAPINew().then(res => {
            if (res.status == 1) {
              localStorage.setItem('isDaren', 1)
              this.enter()
            } else {
              this.$toast(res.msg, 2)
            }
            
          })
        }  
      })
    },
    getName() {
      const nickname = name[Math.floor(Math.random()*name.length)]
      return nickname.at(1) + ''.padEnd(nickname.length-2>6?6:nickname.length-2, '*') + nickname.at(-1)
    },
    start() {
      this.list.push({...this.list[0], id: Math.random()})
      this.list.shift()
      this.carousel = setInterval(() => {
        this.list.push({...this.list[0], id: Math.random()})
        this.list.shift()
      }, 10000)
    },
    enter() {
      this.$router.push('/drive')
    },
    loginoutClick(index) {
      index == 1 ? this.$router.push({ path: '/account' }) : this.loginOut()
    },
    loginOut() {
      loginOutAPI()
        .then(res => {
          this.$utils.clearCookie(this.$glb.fmCookieName)
          this.$store.commit('setAttr', {
            name: 'userInfo',
            val: {},
          })
          this.$toast('请先登录', 3)
          localStorage.removeItem('isDaren')
          this.fmCookieName = this.$utils.getCookie(this.$glb.fmCookieName)
          location.reload()
          sessionStorage.removeItem('isFirst')
        })
        .catch(err => {})
    },
    opDaren() {
      this.showTips = false
      this.openDaren()
    },
    tokefu() {
      this.showTips = false
      window.open('http://q.url.cn/CDKEvl?_type=wpa&qidian=true')
    },
    closeTips() {
      this.showTips = false
    },
  }
}

</script>

<style lang="scss" scoped>
.homepage {
  min-width: 1300px;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  color: #2C2C2C;
  --content-width: 1200px;
  .slogan {
    font-weight: 600;
    font-size: 52px;
    color: #2C2C2C;
    line-height: 73px;
  }
  h1 {
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
  }
  .subtitle {
    color: #8A8B92;
    font-size: 16px;
    line-height: 24px;
  }
  .header {
    width: var(--content-width);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    position: relative;
    .iconBottom{
      width: 12px;
      height: 12px;
    }
    .userGen {
      display: flex;
      align-items: center;
      height: 28px;
      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 12px;
        object-fit: cover;
      }
      span {
        max-width: 130px;
        font-size: 14px;
        font-weight: 500;
        color: #2c2c2c;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .langBox{
      position: relative;
      .lang {
        cursor: pointer;
        margin-right: 24px;
        margin-top: 2px;
        .langTest {
          font-weight: 400;
          font-size: 14px;
          color: #2c2c2c;
          line-height: 22px;
          margin-right: 10px;
        }
        
      }
    }
    .user2 {
      width: 110px;
      // width: 100px;
      height: 50px;
      top: 0;
      position: absolute;
    }
    .user2:hover + .loginOut {
      display: flex;
    }
    .loginOut {
      display: flex;
      flex-direction: column;
      width: 116px;
      height: 82px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.06);
      border-radius: 12px;
      border: 1px solid #e5e5e5;
      position: absolute;
      top: 48px;
      right: 0px;
      z-index: 1200;
      justify-content: center;
      align-items: center;
      display: none;

      .one {
        display: flex;
        width: 100%;
        width: 104px;
        height: 32px;
        align-items: center;
        border-radius: 8px;
        padding-left: 10px;

        .imggr {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
        .ontit {
          font-size: 12px;
          font-weight: 400;
          color: #111734;
        }
      }
      .one:hover {
        background: #f6f6f6;
      }
      .one2 {
        margin-top: 2px;
      }
    }
    .loginOut:hover {
      display: flex;
    }
  }
  .langMoreBox{
    width: 100px;
    max-width: 200px;
    height: 80px;
    top: 0;
    right: 24px;
    position: absolute;
  }
  .langMoreBox:hover +.langMore {
    display: flex;
  }
  .langMore {
    display: flex;
    flex-direction: column;
    width: 108px;
    // height: 82px;
    height: 116px;
    background: #ffffff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    position: absolute;
    top: 34px;
    right: 24px;
    z-index: 1200;
    justify-content: center;
    align-items: center;
    display: none;
    .one {
      display: flex;
      width: 96px;
      height: 32px;
      align-items: center;
      border-radius: 8px;
      padding-left: 10px;
      cursor: pointer;
      .ontit {
        font-weight: 400;
        font-size: 14px;
        color: #2C2C2C;
        line-height: 20px;
      }
    }
    .one:hover {
      background: #f6f6f6;
    }
    .one2 {
      margin-top: 2px;
    }
  }
  .langMore:hover {
    display: flex;
  }

  .container {
    min-width: var(--content-width);
    text-align: center;
    padding: 152px 0;
    :deep(.el-tabs__nav-wrap::after) {
      height: 1px;
      background-color: #E5E5E5;
    }
    :deep(.el-tabs__nav-scroll) {
      display: flex;
      justify-content: center;
    }
    :deep(.el-tabs__item) {
      color: #2C2C2C;
      line-height: 24px;
      padding: 0 60px;
    }
    :deep(.el-tabs__item.is-active) {
      color: #F67942;
    }
    :deep(.el-tabs__active-bar) {
      background: none;
      display: flex;
      justify-content: center;
      height: 3px;
    }
    :deep(.el-tabs__active-bar::before) {
      content: " ";
      width: 68px;
      flex: none;
      height: 3px;
      background-color: #F67942;
      border-radius: 2px 2px 0px 0px;
    }
    :deep(.el-tabs__item:hover) {
      color: #F67942;
    }
    
    .card {
      background: #F9F9F9;
      border-radius: 24px;
      width: 288px;
      height: 196px;
      text-align: left;
      padding: 32px;
      
      line-height: 0;
      p {
        margin: 24px 0 6px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
      }
      span {
        margin-top: 6px;
        color: #8A8B92;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .pane {
      width: 1146px;
      height: 496px;
      background: #FFFFFF;
      border-radius: 24px;
      box-shadow: 0 0 0 0.5px #E5E5E5;
      margin: 64px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        box-shadow: 0px 24px 47px 16px rgba(0,0,0,0.05), 0px 16px 24px 2px rgba(0,0,0,0.04), 0px 8px 10px -5px rgba(0,0,0,0.08);
        border-radius: 24px;
      }
      .text {
        text-align: left;
        padding: 20px 0;
        width: 380px;
        
        b {
          font-weight: 500;
        }
        h2 {
          font-size: 32px;
          line-height: 48px;
          font-weight: 500;
          margin-bottom: 16px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          margin: 12px 0;
          opacity: 0.9;
        }
      }
    }
    .item {
      margin: 24px 10px 0;
      width: 285px;
      height: 110px;
      border-radius: 16px;
      line-height: 24px;
      font-size: 16px;
      padding: 18px 20px;
      display: flex;
      text-align: left;
      border: 1px solid #E5E5E5;
    }
    .comment-box {
      width: 1312px;
      margin: 2px auto 0;
      height: 308px;
      overflow: hidden;
      position: relative;
      > ul {
        height: 100%;
        display: flex;
        align-items: center;
        transform: translateX(-30%);
      }
      .comment {
        flex: none;
        width: 296px;
        height: 168px;
        background: #FFFFFF;
        box-shadow: 0px 8px 32px 0px rgba(0,0,0,0.06);
        border-radius: 16px;
        border: 1px solid #F1F1F1;
        display: inline-block;
        margin: 0 16px;
        padding: 20px 24px 24px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 10s linear;
        > p {
          font-size: 16px;
          line-height: 24px;
          color: #8A8B92;
        }
      }

      /* 确保将离开的元素从布局流中删除
        以便能够正确地计算移动的动画。 */
      .list-leave-active {
        position: absolute;
      }
    }
    .comment-box::before {
      content: " ";
      position: absolute;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient( 90deg, #F9F9F9 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, #F9F9F9 100%);
    }
  }
  .signup {
    width: 180px;
    height: 54px;
    border-radius: 28px;
    font-weight: 500;
    line-height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
  }
  .button {
    color: #fff;
    user-select:none;
    transition: background-color .15s;
    background-color: #7E72F6;
  }
  .button:hover {
    background-color: #9187F7;
  }
  .button:active {
    background-color: #3213D8;
  }
  .gray {
    background: #F9F9F9;
  }
}
</style>